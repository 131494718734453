import { UserOutlined, NotificationOutlined } from "@ant-design/icons"
import { Badge, Space, Typography } from "antd"

function AppHeader() {
  return (
    <div className="AppHeader">
      <Space>
        <Typography.Title style={{padding: '0px 16px 0px 0px'}}>Spectral</Typography.Title>
      </Space>
      {/* <Space style={{padding: '0px 16px 0px 0px'}}>
        <Space>
          <Badge count={7} dot={false}>
            <NotificationOutlined style={{fontSize: 24, padding: '4px 0px 0px 4px'}}></NotificationOutlined>
          </Badge>
        </Space>
        <Space>
          <UserOutlined style={{fontSize: 24, padding: '4px 0px 0px 4px'}}></UserOutlined>
        </Space>
      </Space> */}
    </div>
  )
}

export default AppHeader
