import { Typography, Space, Image, Row, Col } from "antd"
import AppFooter from "../AppFooter"

function PageContent() {
  return (
    <div className="PageContent">
      <Space style={{padding: "16px", flexDirection: "column"}}>
        <Typography.Text style={{fontSize: 20}}>Welcome to Spectral World, this is where it all begins. View and request any of our services below.</Typography.Text>
      </Space>
      <Row style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
        <Col className="ServiceSpace">
          <Typography.Title style={{fontSize: 24, textAlign: "center"}}>Software Development</Typography.Title>
          <Image style={{maxHeight: "400px", maxWidth: "400px"}} preview={false} alt='Software Development' src='/svg/software.svg'/>
          <Typography.Text style={{fontSize: 20}}>Custom computer programs to meet your needs, simplify your operations, and give your customers and employees technological tools to interact with your services in a simpler way.</Typography.Text>
        </Col>
        <Col>
          <Space style={{marginLeft: "8px", marginRight: "8px"}}/>
        </Col>
        <Col className="ServiceSpace">
          <Typography.Title style={{fontSize: 24, textAlign: "center"}}>On-Permise to Cloud Migration</Typography.Title>
          <Image style={{maxHeight: "400px", maxWidth: "400px"}} preview={false} alt='Date Picker' src='/svg/migration.svg'/>
          <Typography.Text style={{fontSize: 20}}>If you still have your business operating in inefficient data centers, this is your opportunity to scale your business and migrate to the cloud to improve your services, increase security and optimize your costs.</Typography.Text>
        </Col>
      </Row>
      <Row style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
        <Col className="ServiceSpace">
          <Typography.Title style={{fontSize: 24, textAlign: "center"}}>Booking Service</Typography.Title>
          <Image style={{maxHeight: "400px", maxWidth: "400px"}} preview={false} alt='Date Picker' src='/svg/booking.svg'/>
          <Typography.Text style={{fontSize: 20}}>If you are providing a service that requires booking appointments, we have our appointment and reservation system at your disposal so that more clients can use your services in a simple way and you can manage your calendar more quickly.</Typography.Text>
        </Col>
        <Col>
          <Space style={{marginLeft: "8px", marginRight: "8px"}}/>
        </Col>
        <Col className="ServiceSpace">
          <Typography.Title style={{fontSize: 24, textAlign: "center"}}>Business Intelligence</Typography.Title>
          <Image style={{maxHeight: "400px", maxWidth: "400px"}} preview={false} alt='Business Intelligence' src='/svg/bi.svg'/>
          <Typography.Text style={{fontSize: 20}}>Knowing your business and your clients is an intelligent decision to achieve a higher quality in the management of your company, as well as increase your profits.</Typography.Text>
        </Col>
      </Row>
    </div>
  )
}

export default PageContent