import React, { useEffect } from 'react';
import './App.css'
import {Button, Space} from 'antd'
import AppHeader from './Components/AppHeader';
import AppFooter from './Components/AppFooter';
import PageContent from './Components/PageContent';


function App() {
  return (
    <div className="App">
      <AppHeader/>
      <PageContent/>
      <AppFooter/>
    </div>
  );
}

export default App;
