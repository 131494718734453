import { Space, Typography } from "antd"
import { CopyrightCircleOutlined } from "@ant-design/icons"

function AppFooter() {
  return (
    <div className="AppFooter">
      <Space>
        <Typography.Text>Contact us by </Typography.Text>
        <Typography.Link href="tel:+17865146966">phone</Typography.Link>
        <Typography.Text>or </Typography.Text>
        <Typography.Link href="mailto:ofi@spectral.world">email</Typography.Link>
      </Space>
      <Space>
        <CopyrightCircleOutlined/>
        <Typography.Text>2023 Spectral LLC</Typography.Text>
      </Space>
    </div>
  )
}

export default AppFooter